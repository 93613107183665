@charset "UTF-8";
.abc-checkbox {
  cursor: default;
  padding-left: 4px; }
.abc-checkbox label {
  cursor: pointer;
  display: inline;
  vertical-align: top;
  position: relative;
  padding-left: 5px; }
.abc-checkbox label::before {
  cursor: pointer;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  margin-left: -1.25rem;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background-color: #fff;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
.abc-checkbox label::after {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 2px;
  margin-left: -1.25rem;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #495057; }
.abc-checkbox input[type="checkbox"],
.abc-checkbox input[type="radio"] {
  position: static;
  margin-left: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1; }
.abc-checkbox input[type="checkbox"]:focus + label::before,
.abc-checkbox input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.abc-checkbox input[type="checkbox"]:checked + label::after,
.abc-checkbox input[type="radio"]:checked + label::after {
  font-family: "FontAwesome";
  content: ""; }
.abc-checkbox input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox input[type="radio"]:indeterminate + label::after {
  display: block;
  content: "";
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px; }
.abc-checkbox input[type="checkbox"]:disabled + label,
.abc-checkbox input[type="radio"]:disabled + label {
  opacity: 0.65; }
.abc-checkbox input[type="checkbox"]:disabled + label::before,
.abc-checkbox input[type="radio"]:disabled + label::before {
  background-color: #e9ecef;
  cursor: not-allowed; }
.abc-checkbox input[type="checkbox"]:disabled + label::after,
.abc-checkbox input[type="radio"]:disabled + label::after {
  cursor: not-allowed; }
.abc-checkbox.abc-checkbox-circle label::before {
  border-radius: 50%; }
.abc-checkbox.checkbox-inline {
  margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007bff;
  border-color: #007bff; }
.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }
.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }
.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }
.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #28a745;
  border-color: #28a745; }
.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff; }
.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545; }
.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8; }
.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107; }
.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745; }
.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  cursor: default;
  padding-left: 4px; }
.abc-radio label {
  cursor: pointer;
  display: inline;
  vertical-align: top;
  position: relative;
  padding-left: 5px; }
.abc-radio label::before {
  cursor: pointer;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #ced4da;
  border-radius: 50%;
  background-color: #fff;
  transition: border 0.15s ease-in-out; }
.abc-radio label::after {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #495057;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
.abc-radio input[type="radio"] {
  position: static;
  margin-left: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1; }
.abc-radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.abc-radio input[type="radio"]:checked + label::after {
  transform: scale(1, 1); }
.abc-radio input[type="radio"]:disabled + label {
  opacity: 0.65; }
.abc-radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }
.abc-radio input[type="radio"]:disabled + label::after {
  cursor: not-allowed; }
.abc-radio.radio-inline {
  margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #007bff; }
.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #007bff; }
.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #007bff; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #dc3545; }
.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #dc3545; }
.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #dc3545; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #17a2b8; }
.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #17a2b8; }
.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #17a2b8; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #ffc107; }
.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffc107; }
.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffc107; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #28a745; }
.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #28a745; }
.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #28a745; }

label .was-validated .form-check-input:invalid .abc-checkbox:before, label
.was-validated .form-check-input:invalid .abc-radio:before, label .form-check-input.is-invalid .abc-checkbox:before, label
.form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545; }
